<template>
    <xz-table ref="ordersRef" :columns="columns" :data="tableData" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'">
    </xz-table>
</template>

<script>
import { saleOrderList, purchaseOrderList } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';

export default {
  name: 'SaleOrders',
  components: {
    xzTable,
  },
  props: {
    saleOrderType: {
      type: String,
      default: undefined,
    },
    code: {
      type: String,
      default: undefined,
    },
  },
  data () {
    return {
      tableData: [],
      columns: [
        {
          title: '关联订单编号',
          dataIndex: 'code',
          key: 'code',
          width: 160,
          ellipsis: true,
        },
        {
          title: '描述',
          key: 'desc',
          width: 160,
          ellipsis: true,
          customRender: (text, record) => {
            return `${record.goodsName || '-'}/${record.specification || '-'}/${record.brandName || '-'}/${record.model || '-'}/${record.goodsCode || '-'}`;
          },
        },
        {
          title: '数量',
          dataIndex: 'currentBillingNum',
          key: 'currentBillingNum',
          width: 160,
          ellipsis: true,
        },
        {
          title: '单位',
          dataIndex: 'unitOfMsment',
          key: 'unitOfMsment',
          width: 160,
          ellipsis: true,
        },
        {
          title: '单价（元）',
          dataIndex: 'unitPrice',
          key: 'unitPrice',
          width: 160,
          ellipsis: true,
        },
        {
          title: '税率',
          dataIndex: 'taxRate',
          key: 'taxRate',
          width: 160,
          ellipsis: true,
        },
        {
          title: '无税金额（元）',
          key: 'noTaxAmount',
          width: 160,
          ellipsis: true,
          customRender: (text, record) => {
            return record.taxlessPrice && record.currentBillingNum ? Math.round(parseInt(record.taxlessPrice * record.currentBillingNum * 100)) / 100 : '';
          },
        },
        {
          title: '含税金额（元）',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 160,
          ellipsis: true,
          customRender: (text, record) => {
            return record.unitPrice && record.currentBillingNum ? Math.round(parseInt(record.unitPrice * record.currentBillingNum * 100)) / 100 : '';
          },
        },
        {
          title: '单据行号',
          key: 'index',
          width: 160,
          ellipsis: true,
          customRender: (text, record, index) => {
            return `${index | 1}`;
          },
        },
      ],
    };
  },
  methods: {
    async loadData () {
      const api = this.saleOrderType === 'saleOrder' ? saleOrderList : purchaseOrderList;

      let params = {};

      params = {
        orderCode: this.code,
        code: this.code,
        findOrderGoodsFlag: true,
        findGoodsFlag: true,
        findDeviceFlag: true,
      };

      const { body } = await api({
        ...params,
      });

      this.tableData = body?.list?.[0]?.goodsList || [];

      this.tableData.forEach(v => {
        v.code = this.code;

        if (this.saleOrderType === 'saleOrder') {
          v.currentBillingNum = v.num;
          v.unitOfMsment = v.unitName;
          v.unitPrice = v.taxPrice;
          v.taxlessPrice = v.unitPrice && v.taxRate ? parseInt((Math.round(v.unitPrice / (1 + v.taxRate) * 100))) / 100 : '';
          v.taxRate = v.taxRate ? parseInt(v.taxRate * 100) + '%' : '';
        }
      });
    },
  },
  mounted () {
    this.loadData();
  },
};
</script>

